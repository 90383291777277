import { Col, Row, Container } from "reactstrap";
import { FaFilePdf } from "react-icons/fa";
import { saveAs } from "file-saver";

const AboutMe = () => {

  const saveCV = () => {
    saveAs(
      "assets/CV-AntonIsaksson2022.pdf",
      "CV-AntonIsaksson2022.pdf"
    );
  };

  const savePB = () => {
    saveAs(
      "assets/PB-AntonIsaksson2022.pdf",
      "PB-AntonIsaksson2022.pdf"
    );
  };


  return (
    <div
      className="skillsContainer"
      style={{ backgroundColor: "rgb(94 172 121)" }}
    >
      <div className="myworksContainer"></div>
      <Container>
        <Row>
          <Col className="col-12" style={{ zIndex: "10" }}>
            <h1 className="heading">About Me</h1>
          </Col>
        </Row>

        <Row>
          <Col
            className="profileImgContainer col-lg-4 col-12"
            style={{ zIndex: "10" }}
          >
            <img src="assets/profile-img.png" alt="..." />
          </Col>
          <Col className="profileTextContainer mx-1" style={{ zIndex: "10" }}>
            <Row style={{ justifyContent: "center" }}>
              <Col className="col-12 p-1">
                <p className="mx-5">
                  Hi there! <br />
                  My name is Anton, I'm 27 years old and lives in Stockholm,
                  Sweden. I'm at the beginning of what I'm hoping is a long
                  career in IT-development. <br />I have a background in Web
                  Development and Architecture, and my decision to try to make
                  a career in coding was first in 2019. I decided to join the
                  program "Full Stack Development" by Code Institute, which was
                  a real eye-opener to this huge new world with endless
                  possibilities. At the moment I work as a Front End / Full
                  Stack Developer in a Swedish owned company in Thailand
                  (remote) but I'm soon available for new
                  job-opportunitys, preferibly in the Stockholm area or remote. <br />
                  Don't hesitate to write me a message if you think I could be a match for you and your company!
                </p>
              </Col>
              <hr style={{ width: "80%" }} />
              <Col className="col-6">
                <Row>
                  <Col className="col-6">
                    <p className="m-0">CV</p> <br />
                    <FaFilePdf onClick={saveCV} className="icon"></FaFilePdf>
                  </Col>

                  <Col className="col-6">
                    <p className="m-0">Personligt brev</p> <br />
                    <FaFilePdf onClick={savePB} className="icon" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutMe;
