import { Container, Row, Col } from "reactstrap";
import {
  FaReact,
  FaPython,
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaNode,
  FaWix,
  FaWordpressSimple,
} from "react-icons/fa";
import {
  SiMongodb,
  SiRedux,
  SiDjango,
  SiExpress,
  SiAdobeillustrator,
  SiWebflow,
} from "react-icons/si";

const HomePage = () => {
  return (
    <>
      <div id="homePageBlue" className="pageContainer">
        <div className="homeContainer"></div>
        <Container>
          <Row>
            <Col className="col-12 heading">
              <h1>Front End | Full Stack | Designer | Developer</h1>
              <h1 id="refl">Front End | Full Stack | Designer | Developer</h1>
            </Col>

            <Col className="col-12">
              <Row>
                <Col className="col-2">
                  <FaHtml5 className="icon icon-l" id="htmlLogo" />
                </Col>
                <Col className="col-2">
                  <FaCss3Alt className="icon icon-l" id="cssLogo" />
                </Col>
                <Col className="col-2">
                  <FaJsSquare className="icon icon-l" id="jsLogo" />
                </Col>
                <Col className="col-2">
                  <FaReact className="icon icon-l" id="reactLogo"/>
                </Col>
                <Col className="col-2">
                  <FaPython className="icon icon-l" id="pythonLogo" />
                </Col>
                <Col className="col-2">
                  <SiDjango className="icon icon-l" id="djLogo" />
                </Col>
              </Row>
            </Col>

            <Col className="mt-5 col-12">
              <Row>
                <Col className="offset-1 col-2">
                  <SiMongodb className="icon icon-l" id="mongoLogo" />
                </Col>
                <Col className="col-2">
                  <SiRedux className="icon icon-l" id="reduxLogo" />
                </Col>
                <Col className="col-2">
                  <SiExpress className="icon icon-l" id="expressLogo"/>
                </Col>
                <Col className="col-2">
                  <FaWix className="icon icon-l" id="wixLogo" />
                </Col>
                <Col className="col-2">
                  <FaWordpressSimple className="icon icon-l" id="wpLogo"/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HomePage;
