import { Col, Row, Container } from "reactstrap";
import Project from "../components/Project";

const MyWorks = () => {
  return (
    <>
      <div className="projectsContainer" id="homePageBlue">
        <div className="myworksContainer"></div>
        <Container>
          <Row>
            <Col className="col-12" style={{ zIndex :10 }}>
              <h1 className="heading">My Works</h1>
            </Col>
            <Col className="col-12">
              <Project />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MyWorks;
