import SkillsTable from "../components/SkillsTable";
import { Container, Row, Col } from "reactstrap";

const Skills = () => {
  return (
    <div className="skillsContainer" style={{ backgroundColor: "rgb(94 172 121)" }}>
      <div className="myworksContainer"></div>
      <Container>
        <Row>
          <Col className=" col-12" style={{ zIndex: '10' }}>
            <h1 className="heading">Skill Areas</h1>
          </Col>
          <Col className="col-12">
            <SkillsTable />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Skills;
