import "charts.css";
import { Container, Row, Col } from "reactstrap";
import {
  FaReact,
  FaPython,
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaNode,
  FaWix
} from "react-icons/fa";
import { SiMongodb, SiRedux, SiDjango, SiExpress, SiWebflow, SiAdobeillustrator, SiUnrealengine } from "react-icons/si";

const SkillsTable = () => {
  return (
    <Container style={{ zIndex: '11' }}>
      <Col ><h6 className="skillsSecondHeading">(Welcome to the "Skills" page! Just so there is no misunderstandings here; the height of the pillars is to showcase what I consider is my knowledge in a specific field compared to <span style={{ fontWeight: '700' }}> my own knowledge</span> in other fields shown here. )  </h6></Col>
      <Row>
        <Col className="col-lg-6 col-12">
          <table
            id="stacked-example-4"
            className="charts-css column hide-data show-heading show-labels show-primary-axis show-3-secondary-axes data-spacing-10 multiple stacked"
          >
            <caption> Front End </caption>

            <tbody>
              <tr>
                <th scope="row"> HTML </th>
                <td id="darkblue" className="skillStackCol size-70">
                  <FaHtml5 className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row"> CSS / SCSS </th>
                <td id="red" className="skillStackCol size-80">
                  <FaCss3Alt className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row"> JS </th>
                <td id="yellow" className="skillStackCol size-60">
                  <FaJsSquare className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row"> ReactJS </th>
                <td id="blue" className="skillStackCol size-70">
                  <FaReact className="icon icon-sm" />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col className="col-lg-6 col-12">
          <table
            id="stacked-example-4"
            className="charts-css column hide-data show-heading show-labels show-primary-axis show-3-secondary-axes data-spacing-10 multiple stacked"
          >
            <caption> Full Stack / Back End </caption>

            <tbody>
              <tr>
                <th scope="row"> MongoDB </th>
                <td id="green" className="skillStackCol size-50">
                  <SiMongodb className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row"> Redux </th>
                <td id="purple" className="skillStackCol size-30">
                  <SiRedux className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row"> Python / Django </th>
                <td id="yellow" className="skillStackCol size-60">
                  <FaPython className="icon icon-sm" />
                  <SiDjango className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row">MERN</th>
                <td id="pink" className="skillStackCol size-50">
                  <Row>
                    <Col className="col-12 my-1">
                      <SiMongodb className="icon icon-xs" />
                      <SiExpress className="icon icon-xs" />
                    </Col>
                    <Col className="col-12 my-1">
                      <SiRedux className="icon icon-xs" />
                      <FaReact className="icon icon-xs" />
                    </Col>
                   
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>
        </Col>

        <Col className="col-lg-6 offset-lg-3 col-12 mt-4">
          <table
            id="stacked-example-4"
            className="charts-css column hide-data show-heading show-labels show-primary-axis show-3-secondary-axes data-spacing-10 multiple stacked"
          >
            <caption> Design and Others </caption>

            <tbody>
              <tr>
                <th scope="row"> Wix </th>
                <td id="yellow2" className="skillStackCol size-70">
                  <FaWix className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row"> WebFlow </th>
                <td id="purpleBlue" className="skillStackCol size-40">
                  <SiWebflow className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row"> Adobe Illustrator </th>
                <td id="orange" className="skillStackCol size-60">
                  <SiAdobeillustrator className="icon icon-sm" />
                </td>
              </tr>
              <tr>
                <th scope="row">Unreal Engine</th>
                <td id="black" className="skillStackCol size-50">
                <SiUnrealengine className="icon icon-sm" />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        
      </Row>
    </Container>
  );
};

export default SkillsTable;

// charts-css bar data-spacing-10