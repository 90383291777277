import ContactForm from "../components/ContactForm";
import { Container, Row, Col } from "reactstrap";

const Contact = () => {
  return (
    <div className="skillsContainer" id="homePageBlue">
      <div className="myworksContainer"></div>
      <Container >
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Col className="col-12" style={{ zIndex:'12'}}>
            <h1 className="heading">Contact</h1>
          </Col>
          <Col className="col-lg-6 col-10 formContainer" style={{ zIndex:'12' }}>
            <ContactForm />
          </Col>
          <Col className="offset-lg-2 col-sm-12 col-lg-4" style={{ zIndex:'12'}}>
            <img src="assets/handshake.png" className="contactImg" alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
