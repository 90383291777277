import { FaGithub, FaDesktop, FaReact } from "react-icons/fa";
import { Col, Row, Container } from "reactstrap";
import { projects } from "../db";

const Project = () => {
  return (
    <>
      {projects.map((project) => {
        return (
          <Container
            key={project.id}
            className="projectContainer"
            style={{
              backgroundColor: project.even
                ? "rgb(115 190 117)"
                : "rgb(27 140 183)",
              borderRadius: project.even
                ? "95% 4% 97% 5%/4% 94% 3% 95%"
                : "455% 15% 39% 8% / 1% 150% 10% 190%",
              padding: "20px",
            }}
          >
            <Row>
              {project.even ? (
                <>
                  <Col className="col-lg-7 col-12 my-2">
                    <h1 className="projectHeading">{project.name}</h1>
                  </Col>
                  <Col className="col-7 col-lg-7 col-12 img-col">
                    <img src={project.img} className="img-desktop" alt="..." />
                  </Col>

                  <Col className="desc-col">
                    <Row style={{ textAlign: "left" }}>
                      <Col className="col-lg-12 align-items-center d-flex justify-content-center">
                        <h4>{project.descriptionHeading}</h4>
                      </Col>
                      <Col className="col-lg-12 align-items-center d-flex justify-content-center" >
                        {project.descriptionList.map((listItem) => {
                          return (
                            <ul  className=" col-lg-12 mt-2 descList" key={listItem.id}>
                              <li>
                                <span>Year: </span>
                                {" " + listItem.year}
                              </li>
                              <li>
                                <span>Language: </span>
                                {" " + listItem.language}
                              </li>
                            </ul>
                          );
                        })}
                      </Col>
                      <Col className="col-12">
                        <p>{project.description}</p>
                      </Col>
                      <Col style={{ textAlign: "center" }}>
                        <Row>
                          <Col>
                            {project.githubLink ? (
                              <a
                                href={project.githubLink}
                                target="_blank"
                                alt=""
                              >
                                <FaGithub className="icon" />
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col>
                            {project.websiteLink ? (
                              <a
                                href={project.websiteLink}
                                target="_blank"
                                alt=""
                              >
                                <FaDesktop className="icon" />
                              </a>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </>
              ) : (
                <>
                  <Col className="col-lg-5 col-12 offset-lg-6 my-2">
                    <h1 className="projectHeading">{project.name}</h1>
                  </Col>
                  <Col className="col-12">
                    <Row>
                      <Col className="desc-col col-lg-5">
                        <Row style={{ textAlign: "left" }}>
                          <Col className="col-lg-12 align-items-center d-flex justify-content-center">
                            <h4>{project.descriptionHeading}</h4>
                          </Col>
                          <Col>
                            {project.descriptionList.map((listItem) => {
                              return (
                                <ul className="descList mt-2 col-lg-12" key={listItem.id}>
                                  <li>
                                    <span>Year: </span>
                                    {" " + listItem.year}
                                  </li>
                                  <li>
                                    <span>Language: </span>
                                    {" " + listItem.language}
                                  </li>
                                </ul>
                              );
                            })}
                          </Col>
                          <Col className="col-12">
                            <p>{project.description}</p>
                          </Col>
                          <Col style={{ textAlign: "center" }}>
                            <Row>
                              <Col>
                                {project.githubLink ? (
                                  <a
                                    href={project.githubLink}
                                    target="_blank"
                                    alt=""
                                  >
                                    <FaGithub className="icon" />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Col>
                              <Col>
                                {project.websiteLink ? (
                                  <a
                                    href={project.websiteLink}
                                    target="_blank"
                                    alt=""
                                  >
                                    <FaDesktop className="icon" />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-lg-7 col-12 img-col-2">
                        <img
                          src={project.img}
                          className="img-desktop-2"
                          alt="..."
                        />
                      </Col>
                    </Row>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        );
      })}
    </>
    // <Container style={{ backgroundColor: "#94B49F" }}>
    //   <Row style={{ height: "65vh" }}>
    //     <Col className="col-7 mt-3">
    //       <h1 className="projectHeading">workStation.</h1>
    //     </Col>
    //     <Col className="col-5 mt-3">
    //       <Row>
    //         <Col>
    //           <FaReact className="icon icon-sm" />
    //         </Col>
    //         <Col>
    //           <FaReact className="icon icon-sm" />
    //         </Col>
    //         <Col>
    //           <FaReact className="icon icon-sm" />
    //         </Col>
    //         <Col>
    //           <FaReact className="icon icon-sm" />
    //         </Col>
    //       </Row>
    //     </Col>
    //     <Col className="col-7 img-col">
    //       <img
    //         src="assets/allscreensizes-workstation.png"
    //         className="img-desktop"
    //         alt="..."
    //       />
    //     </Col>
    //     <Col className="desc-col">
    //       <Row>
    //         <Col className="col-12">
    //           <h4>Full Stack MERN App</h4>
    //         </Col>
    //         <Col className="col-12">
    //           <p>
    //             Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
    //             quam, sapiente animi tenetur et corporis minus, perferendis
    //             delectus quas incidunt, impedit adipisci enim fuga possimus ut.
    //             Tempore enim necessitatibus officiis! Lorem ipsum dolor sit amet
    //             consectetur adipisicing elit. Fugiat quam, sapiente animi
    //             tenetur et corporis minus, perferendis et corporis minus, sit
    //             amet consectetur adipisicing elit.
    //           </p>
    //         </Col>
    //         <Col>
    //           <Row>
    //             <Col>
    //               <a
    //                 href="https://github.com/AntonIsaksson/gamedesign-2d"
    //                 target="_blank"
    //                 alt=""
    //               >
    //                 <FaGithub className="icon" />
    //               </a>
    //             </Col>
    //             <Col>
    //               <FaDesktop className="icon" />
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     </Col>
    //   </Row>
    // </Container>
  );
};

export default Project;
