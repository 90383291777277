//Components
import Header from "./components/Header";
import Footer from "./components/Footer";
//Pages
import HomePage from "./pages/HomePage";
import AboutMe from "./pages/AboutMe";
import MyWorks from "./pages/MyWorks";
import Skills from "./pages/Skills";
import Contact from "./pages/Contact";
//Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//CSS'
// import { Transition } from "react-transition-group";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "reactstrap";
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/works" element={<MyWorks />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
