import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Container,
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
} from "reactstrap";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { useState, useEffect } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpointMedium = 767;
  // const breakpointSmall = 500;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, [width]);

  return (
    // <div className="headerContainer">
    <Navbar className="headerContainer" dark expand="md">
      <Row className="alignCenter">
        {width > breakpointMedium ? (
          <>
            <Col className={width > breakpointMedium ? "col-2" : ""}>
              <Link to="/">
                <img src="assets/logo4.png" className="img-logo" alt="..." />
              </Link>
            </Col>
            <Col className={width > breakpointMedium ? "col-6" : ""}>
              <Collapse isOpen={isOpen} navbar className="menuItems">
                <Nav className="mr-auto" navbar>
                  <Link
                    to="/about"
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    About Me
                  </Link>
                </Nav>
                <Nav className="mr-auto" navbar>
                  <Link
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    to="/works"
                  >
                    My Projects
                  </Link>
                </Nav>
                <Nav className="mr-auto" navbar>
                  <Link
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    to="/skills"
                  >
                    Tool Kit
                  </Link>
                </Nav>
                <Nav className="mr-auto" navbar>
                  <Link
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </Nav>
              </Collapse>
            </Col>
            <Col className={width > breakpointMedium ? "col-2" : ""}>
              <Row>
                <Col className="col-3 offset-4">
                  <a href="https://github.com/AntonIsaksson" target="_blank">
                    <FaGithub className="icon icon-sm" />
                  </a>
                </Col>
                <Col className="col-3">
                  <a
                    href="https://www.linkedin.com/in/anton-isaksson-1b8676163/"
                    target="_blank"
                  >
                    <FaLinkedin className="icon icon-sm" />
                  </a>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col className="col-3">
              <NavbarToggler
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              />
            </Col>

            <Collapse isOpen={isOpen} navbar className="menuItems">
              <Nav className="mr-auto" navbar>
                <Link
                  to="/about"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  About Me
                </Link>
              </Nav>
              <Nav className="mr-auto" navbar>
                <Link
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  to="/works"
                >
                  My Projects
                </Link>
              </Nav>
              <Nav className="mr-auto" navbar>
                <Link
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  to="/skills"
                >
                  Tool Kit
                </Link>
              </Nav>
              <Nav className="mr-auto" navbar>
                <Link
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                  to="/contact"
                >
                  Contact
                </Link>
              </Nav>
            </Collapse>

            <Col className="col-6">
              <Row>
                <Col className="col-3 offset-4">
                  <FaGithub className="icon icon-sm" />
                </Col>
                <Col className="col-3">
                  <FaLinkedin className="icon icon-sm" />
                </Col>
              </Row>
            </Col>
            <Col className="col-3">
              <Link to="/">
                <img src="assets/logo4.png" className="img-logo" alt="..." />
              </Link>
            </Col>
          </>
        )}
      </Row>
    </Navbar>
    // </div>

    // <div className="headerContainer">
    //   <Container id="headerInnerContainer">
    //     <Row className="alignCenter">
    //       <Col className="col-2">
    //         <Link to="/">
    //           <img src="assets/logo4.png" className="img-logo" alt="..." />
    //         </Link>
    //       </Col>
    //       <Col className="menuItems col-8">
    //         <Row className="mx-4">
    //           <Col>
    //             <Link to="/about">About Me</Link>
    //           </Col>
    //           <Col>
    //             <Link to="/works">My Works</Link>
    //           </Col>
    //           <Col>
    //             <Link to="/skills">Skill Areas</Link>
    //           </Col>
    //           <Col>
    //             <Link to="/contact">Contact</Link>
    //           </Col>
    //         </Row>
    //       </Col>
    //       <Col className="col-1">
    //         <Row>
    //           <Col className="col-6">
    //             <FaGithub className="icon icon-sm" />
    //           </Col>
    //           <Col className="col-6">
    //             <FaLinkedin className="icon icon-sm"/>
    //           </Col>
    //         </Row>
    //       </Col>
    //     </Row>
    //   </Container>
    // </div>
  );
};

export default Header;
