export const projects = [
  {
    id: 1,
    name: "Slow Down Beachvolleyfestival - Åhus",
    img: "assets/allscreens-beachvolley.png",
    descriptionHeading: "ReactJS (with payment integration)",
    descriptionList: [
      {
        id: 1,
        year: '2022',
        language: 'ReactJS',
        type: 'Work Project'
      }
    ],
    description: "The main website for Slow Down Beachvolleyfestival. On the site you can Sign Up to all of the companies events in the summer of 2022. Includes payment integration with 'Swish Handel'.",
    githubLink: "",
    websiteLink: "https://www.slowdownbeachvolley.se/",
    even: false,
  },
  {
    id: 2,
    name: "workStation.",
    img: "assets/allscreensizes-workstation.png",
    descriptionHeading: "Full Stack MERN App",
    descriptionList: [
      {
        id: 2,
        year: '2022',
        language: 'MERN (MongoDB, Express, React/Redux & NodeJS)',
        type: 'Personal Project'
      }
    ],
    description: "A project in process! I wanted to make my own Fullstack project using the MERN-Stack, and at the same time build something that I can use in my day-to-day life. The main purpose with the application is to have 1 single place to use for structuring my workdays. A todo-part with a full CRUD-system, a calender, timer, etc... There is many like it, but this one is built to fit my own needs fully! ",
    githubLink: "",
    websiteLink: "",
    even: true,
  },
  {
    id: 3,
    name: "Slow Down Padel",
    img: "assets/allscreensizes-slowdownpadel.png",
    descriptionHeading: "FrontEnd / Fullstack Work Project",
    descriptionList: [
      {
        id: 3,
        year: '2022',
        language: 'React & C++ (.NET)',
        type: 'Work Project'
      }
    ],
    description: 'A Bookingsystem for Slow Down Resort´s (Thailand) Padel Courts. Made by me and my working colleague; me with focus on the client side made with ReactJS and him with focus on the server side. The system is today used by the resort and their guests to book and pay for a court-time.', 
    githubLink: "",
    websiteLink: "https://boka.slowdownlanta.se/Padel",
    even: false,
  },
  {
    id: 4,
    name: "Game Design - 2D",
    img: "assets/allscreensizes-gameDesign.png",
    descriptionHeading: "Fullstack",
    descriptionList: [
      {
        id: 4,
        year: '2020',
        language: 'Django(Python), Postgres, jQuery, AWS(storage) & Stripe (payment)',
        type: 'Personal Project'
      }
    ],
    description:
      "My 4th and final schoolproject on Code Academy. App idea was an online store with the focusgroup 'game-developers/designers', where you as a visitor can register and either search for and download (for free or for payment) 2D characters and landscapes etc. for 2D games.",
    githubLink: "https://github.com/AntonIsaksson/gamedesign-2d",
    websiteLink: "https://gamedesign-2d.herokuapp.com/",
    even: true,
  },
  {
    id: 5,
    name: "Halloween Memory",
    img: "assets/allscreensizes-memory.png",
    descriptionHeading: "FrontEnd",
    descriptionList: [
      {
        id: 5,
        year: '2019',
        language: 'HTML, CSS & JS (jQuery)',
        type: 'Personal Project'
      }
    ],
    description:
      "My first ever 'full' Frontend project. Made with plain HTML & CSS, and jQuery. A very fun experience where I learned a lot. A 'simple' Memory Game with a halloween-theme. Clear all 3 levels and you win the game!",
    githubLink: "https://github.com/AntonIsaksson/mystery-memory",
    websiteLink: "https://antonisaksson.github.io/mystery-memory/",
    even: false,
  },
  {
    id: 6,
    name: "Svenska Skolan Lanta",
    img: "assets/allscreensizes-skolan.png",
    descriptionHeading: "CMS Web Design (Wix)",
    descriptionList: [
      {
        id: 6,
        year: '2018',
        language: 'Wix.com',
        type: 'Personal Project'
      }
    ],
    description: "So before I new barely anything about either web development or coding, I got a job to make websites for two companies in Thailand. This one was for a Swedish School based on Koh Lanta, and the website is up and running still. Nothing fancy now that I look back at it, but a good start and eye-opener for the Web Development business.",
    githubLink: "",
    websiteLink: "http://www.svenskaskolanlanta.se/",
    even: true,
  },
  {
    id: 7,
    name: "Slow Down Resort",
    img: "assets/allscreensizes-slowdown.png",
    descriptionHeading: "CMS Website",
    descriptionList: [
      {
        id: 7,
        year: '2022',
        language: 'MERN (MongoDB, Express, React/Redux & NodeJS)',
        type: 'Personal Project'
      }
    ],
    description:
      "So before I new barely anything about either web development or coding, I got a job to make websites for two companies in Thailand. This one was for a Swedish-owned Resort based on Koh Lanta, and the website is up and running still. Nothing fancy now that I look back at it, but a good start and eye-opener for the Web Development business. ",
    githubLink: "",
    websiteLink: "https://www.slowdownlanta.se/",
    even: false,
  },
  
  
  
];
