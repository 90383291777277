import { Container } from "reactstrap";
import { useState } from "react";

import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("user_YnkXPlVwLYoYY1aere2EN");

const ContactForm = () => {
  const [message, setMessage] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(true);
    emailjs
      .sendForm(
        "outlook",
        "template_3z24149",
        e.target,
        "user_YnkXPlVwLYoYY1aere2EN"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setTimeout(() => setMessage(false), 5000);
  };

  return (
    <form className="form" method="post" onSubmit={handleSubmit}>
      <div className="content">
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="name" id="name" placeholder="ex. 'John Snow'" />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="ex. 'john.snow@knowsnothing.com'"
          />
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea
            type="text"
            name="message"
            id="message"
            placeholder="ex. 'Hi! It would be nice to know something...'"
          />
        </div>

        {message ? (
          <div className="col-12 messageContainer">
            <span>Thanks, I'll reply ASAP!</span>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <button className="btns">Send</button>
          </div>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
